import type { Notice } from '~~/types/notice'
import apiAllDeliver from '~/const/apiAllDeliver'
import apiDraft from '~/const/apiDraft'
import apiNoticeLevel from '~/const/apiNoticeLevel'
import apiNoticeType from '~/const/apiNoticeType'
import apiPublishStopFlg from '~/const/apiPublishStopFlg'
import { AllDeliver } from '~/enum/allDeliver'
import { Draft } from '~/enum/draft'
import { NoticeLevel } from '~/enum/noticeLevel'
import { NoticeType } from '~/enum/noticeType'
import { PublishDiv } from '~/enum/publishDiv'
import { PublishStopFlg } from '~/enum/publishStopFlg'
import GetNoticeDatasService from '~/services/GetNoticeDatasService'
import type { F09019Dto } from '~~/types/F09019Dto'
import type { F09019Params } from '~~/types/F09019Params'
import type { NoticeRes } from '~~/types/noticeRes'
import { convertDivForFront } from '~/libs/convertDivForFront'
import NOTICE_LIMIT_CONST from '~/const/noticeLimitConst'
import RegisterNoticeDatasService from '~/services/NoticeDatasService'
import apiSchy from '~/const/apiSchy'
import { Schy } from '~/enum/schy'
import { convertForApi } from '~/libs/convertDivForApi'
import { dateFormatter } from '~/libs/dateFormatter'
import type { F09020Dto } from '~~/types/F09020Dto'
import type { NoticeUpdateOrCreateParams } from '~~/types/noticeUpdateOrCreateParams'
import { convertHtmlText } from '~/libs/convertHtmlText'
import { DateUtil } from '~/utils/date-util'
import { inject } from 'vue'
import { updateIsBackgroundLoadingKey } from '~/const/updateIsBackgroundLoadingKey'
import * as Sentry from '@sentry/vue'
import { defineStore } from 'pinia'
import { ref } from 'vue'

const NOTICE_STORE_KEY = 'NoticeStore'

export const useNoticeStore = defineStore(NOTICE_STORE_KEY, () => {

  /**
   * お知らせ一覧
   */
  const noticeList =  ref<Notice[]>([])

  /**
   * お知らせのIDを基にお知らせを取得する処理
   * @param id お知らせのID
   */
  const getNoticeById = (id: number) => {
    return noticeList.value.find((notice) => notice.id === id)!
  }

  const resetNoticeList = () => {
    noticeList.value.splice(0)
  }

  // Noticeデータを取得する関数
  const getNoticeDatas = async (params: F09019Params) => {
    // APIから取得したデータをNotice型に格納
    const result: NoticeRes = await GetNoticeDatasService.get(params)
    switch (result.data.code) {
      case 0:
        // データ取得が成功した場合、
        const f09019DtoList: F09019Dto[] = result.data.f09019Dtos
        sessionStorage.setItem('noticesCount', result.data.noticesCount)
        f09019DtoList.forEach((element: F09019Dto) => {
          const noticeData: Notice = {
            id: Number(element.id),
            noticeTitle: element.noticeTitle,
            noticeCont: decodeURIComponent(element.noticeCont),
            noticeTypeDiv: NoticeType[convertDivForFront(element.noticeTypeDiv, apiNoticeType) as keyof typeof NoticeType],
            pubPlanStartDt: element.startTimeStr,
            pubPlanEndDt: element.endTimeStr,
            cretDatime: element.cretDatime,
            draftflg: Draft[convertDivForFront(element.noticeStsDiv.toString(), apiDraft) as keyof typeof Draft],
            orgId: element.orgId.toString(),
            noticeLevelDiv: NoticeLevel[convertDivForFront(element.noticeLevelDiv, apiNoticeLevel) as keyof typeof NoticeLevel],
            allDeliverFlg: AllDeliver[convertDivForFront(element.allDeliverFlg, apiAllDeliver) as keyof typeof AllDeliver],
            attachFilePath: element.attachFilePath,
            titleImgPath: element.titleImgPath,
            publishStopFlg: PublishStopFlg[convertDivForFront(element.publishStopFlg.toString(), apiPublishStopFlg) as keyof typeof PublishStopFlg],
            publishDiv: setPublishDiv(element),
            originalFilenm: element.originalFilenm,
          }
          // リストにデータを追加
          noticeList.value.push(noticeData)
        });
        break
      case -1:
        // データが0件の場合、
        sessionStorage.setItem('noticesCount', '0')
        break
      default:
        // TODO: エラー処理
        break
    }
  }

  const loadNoticeDatas = async () => {
    const noticeParams: F09019Params = {
      limit: NOTICE_LIMIT_CONST,
      order: 0,
    }
    const backgroundLoading = inject(updateIsBackgroundLoadingKey)!
    backgroundLoading?.('loadNoticeDatas', true)
    resetNoticeList()
    await getNoticeDatas(noticeParams).catch((error) => {
      // Sentryにエラーを送信
      Sentry.captureException(error)
    }).finally(() => {
      backgroundLoading?.('loadNoticeDatas', false)
    })
  }

  // 掲載状況区分を算出する関数
  // 引数：F09019Dto
  // 掲載状況区分(PublishDiv)のenum値
  const setPublishDiv = (element: F09019Dto) => {
    let ret: PublishDiv = PublishDiv.publishStop;
    // 掲載停止フラグ
    const publishStopFlg = PublishStopFlg[convertDivForFront(element.publishStopFlg.toString(), apiPublishStopFlg) as keyof typeof PublishStopFlg]

    // 掲載停止フラグが掲載の場合
    if (publishStopFlg === PublishStopFlg.publish) {
      const pubPlanStartDt: Date = new DateUtil(element.startTimeStr).date
      const pubPlanEndDt: Date = new DateUtil(element.endTimeStr).date

      // 掲載開始または終了が設定されていない場合は、下書き
      if (isNaN(pubPlanStartDt.getTime()) || isNaN(pubPlanEndDt.getTime())) {
        ret = PublishDiv.draft
      } else {
        const draft: Draft = Draft[convertDivForFront(element.noticeStsDiv.toString(), apiDraft) as keyof typeof Draft]
        // 下書きフラグが設定されている場合は、下書き
        if (draft === Draft.draft) {
          ret = PublishDiv.draft
        }
        // それ以外の場合は、掲載開始終了時間で判定
        else {
          const nowDate = new DateUtil().date
          // 掲載前
          if (nowDate < pubPlanStartDt) {
            ret = PublishDiv.beforePublish
          }
          // 掲載中
          else if (pubPlanStartDt <= nowDate && nowDate <= pubPlanEndDt) {
            ret = PublishDiv.nowPublish
          }
          // 掲載終了
          else if (pubPlanEndDt < nowDate) {
            ret = PublishDiv.endPublish
          }
        }
      }
    } else {
      ret = PublishDiv.publishStop;
    }
    return ret
  }

  const updateOrCreateNotice = async (params: NoticeUpdateOrCreateParams) => {
    // 配信対象リストを作成
    const stuIdList: string[] = []
    const orgIdList: string[] = []
    const stuList: string[] = []
    params.deliveryTarget.forEach(element => {
      stuIdList.push(element.usrId)
      orgIdList.push(element.orgId)
      // enumのvalueからkeyを取得
      let schyKey = ''
      Object.entries(Schy).forEach(([key, value]) => {
        if (value == element.schy) {
          schyKey = key
          return
        }
      });
      const obj = {
        stuId: element.usrId,
        schy: element.schy,
        stuNm: element.name,
        schyDiv: convertForApi(schyKey, apiSchy),
        orgId: element.orgId,
      }
      stuList.push(JSON.stringify(obj))
    });

    // enumのvalueからkeyを取得
    let noticeLevelKey = ''
    Object.entries(NoticeLevel).forEach(([key, value]) => {
      if (value == params.isImportant) {
        noticeLevelKey = key
        return
      }
    });
    const publishStopFlg = params.isPublishStop ? 1 : 0
    const f09020dto: F09020Dto = {
      noticeId: params.noticeId != 'new' ? Number(params.noticeId) : null,
      noticeTitle: encodeURIComponent(params.subjectText),
      noticeCont: encodeURIComponent(convertHtmlText(params.bodyText)),
      noticeTypeDiv: convertForApi('notice', apiNoticeType)!,
      noticeLevelDiv: convertForApi(noticeLevelKey, apiNoticeLevel)!,
      pubPlanStartDt: dateFormatter(params.startDatetime, 'YYYYMMDDhhmm', '/'),
      pubPlanEndDt: dateFormatter(params.endDatetime, 'YYYYMMDDhhmm', '/'),
      mgrFlg: '0',
      stu: JSON.stringify(stuList),
      stuIdList: stuIdList,
      orgIdList: orgIdList,
      psd: dateFormatter(params.startDatetime, 'YYYYMMDDhhmm', '/'),
      ped: dateFormatter(params.endDatetime, 'YYYYMMDDhhmm', '/'),
      noticeStsDiv: params.isDraft ? Number(convertForApi('draft', apiDraft)) : Number(convertForApi('unDraft', apiDraft)),
      publishStopFlg: publishStopFlg,
    }

    // APIに渡すパラメータをFormDataにセット
    const formData = new FormData()
    formData.append('f09020dto', JSON.stringify(f09020dto))
    params.attachFiles.forEach(fileMap => {
      if (fileMap.filePath.startsWith('blob')) {
        // 今回添付したファイル
        formData.append('files', fileMap.file)
      } else {
        // 元々添付されているファイル
        formData.append('originalPaths', fileMap.originalFilePath)
      }
    })
    if (typeof params.reproductionId != 'undefined') {
      // 複製元のID
      formData.append('reproductionId', params.reproductionId)
    }

    // DB更新、store再設定
    return await RegisterNoticeDatasService.update(formData).then(async code => {
      if(code != 0) {
        return false
      }
      // store再設定
      const noticeParams: F09019Params = {
        limit: NOTICE_LIMIT_CONST,
        order: 0,
      }
      resetNoticeList()
      await getNoticeDatas(noticeParams)
    })
  }

  return {
    noticeList,
    getNoticeById,
    resetNoticeList,
    getNoticeDatas,
    loadNoticeDatas,
    updateOrCreateNotice,
  }
}, {
  persist: {
    storage: sessionStorage,
  }
})
